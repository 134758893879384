// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming

@use "@angular/material" as mat;
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

//Creating a custom pallete to use for theme
$color-primary: (
  50: #e4e4ed,
  100: #bcbbd1,
  200: #8f8eb3,
  300: #626094,
  400: #403e7d,
  500: #1e1c66,
  600: #1a195e,
  700: #161453,
  800: #121149,
  900: #0a0938,
  A100: #7171ff,
  A200: #3e3eff,
  A400: #0b0bff,
  A700: #0000f1,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #ffffff,
    400: #ffffff,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #000000,
    A200: #ffffff,
    A400: #ffffff,
    A700: #ffffff,
  ),
);

$color-secondary: (
  50: #e0f7fd,
  100: #b3eafb,
  200: #80ddf9,
  300: #4dcff6,
  400: #26c4f4,
  500: #00baf2,
  600: #00b3f0,
  700: #00abee,
  800: #00a3ec,
  900: #0094e8,
  A100: #ffffff,
  A200: #dcf1ff,
  A400: #a9dcff,
  A700: #90d1ff,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #000000,
    500: #000000,
    600: #000000,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #000000,
    A200: #000000,
    A400: #000000,
    A700: #000000,
  ),
);

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
// $BookingWep-primary: mat.define-palette(mat.$indigo-palette);
// $BookingWep-accent: mat.define-palette(mat.$pink-palette, A200, A100, A400);
$bookingwep-primary: mat.define-palette($color-primary);
$bookingwep-secondary: mat.define-palette($color-secondary);
// The warn palette is optional (defaults to red).
$bookingwep-warn: mat.define-palette(mat.$red-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$bookingwep-theme: mat.define-light-theme(
  (
    color: (
      primary: $bookingwep-primary,
      accent: $bookingwep-secondary,
      warn: $bookingwep-warn,
    ),
  )
);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($bookingwep-theme);

/* You can add global styles to this file, and also import other style files */

html,
body {
  height: 100%;
}
body {
  margin: 0;
  font-family: Inter, Roboto, "Helvetica Neue", sans-serif;
}

/* Importing Bootstrap SCSS file. */

@import "~bootstrap/scss/functions";

@import "~bootstrap/scss/variables";

$carousel-control-color: $primary !important;
$carousel-control-prev-icon-bg: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='#{$carousel-control-color}'><path d='M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z'/></svg>") !default;
$carousel-control-next-icon-bg: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='#{$carousel-control-color}'><path d='M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z'/></svg>") !default;

@import "~bootstrap/scss/mixins";

@import "~bootstrap/scss/root";

@import "~bootstrap/scss/bootstrap";
/* Importing Datepicker SCSS file. */
@import "node_modules/ngx-bootstrap/datepicker/bs-datepicker";
@font-face {
  font-family: Nirmala UI;
  src: url(/assets/fonts/Nirmala.ttf);
}
@font-face {
  font-family: Inter;
  src: url(./assets/font/Inter/static/Inter-Regular.ttf);
}

:root {
  --space: 2rem;
  --space-xs: calc(var(--space) / 3);
  --space-sm: calc(var(--space) / 2);
  --space-md: calc(var(--space) * 2);

  /*NEW SCHEMA*/
  /*COLORS SCHEHMA*/
  --color-primary: #1e1c66;
  --color-secondary: #00baf2;
  --color-white: #ffffff;
  --color-header-bg: #e9e9e9;
  --color-bg: #f8f8f8;
  --color-lightgrey: #d1d1d1;
  --color-lightgrey2: #e6e6e6;
  --color-grey: #787878;
  --color-darkgrey: #333333;
  --color-footer-text: #5b5d5e;
  --color-border: #bcb7b7;

  /*Font SCHEHMA*/
  /*FONT FAMILIES*/
  --first-en: Inter, "Lucida Sans Unicode", Geneva, Verdana, sans-serif;
  --secound-en: Inter, Cambria, Cochin, Georgia, Times, "Times New Roman", serif;
  --third-en: Inter, Lato;
  --fourth-en: Inter, Nirmala UI;
  --font-family-en: Inter;

  /*FONT SIZES*/
  --fs-12px:0.75rem;
  --fs-13px: 0.8125em;
  --fs-14px:0.875rem;
  --fs-15px: 0.938rem;
  --fs-12px:0.75rem
  --fs-14px:0.875;
  --fs-15px:0.938rem;
  --fs-16px: 1rem;
  --fs-17px: 1.063rem;
  --fs-18px: 1.125rem;
  --fs-19px: 1.188rem;
  --fs-20px: 1.25rem;
  --fs-26px: 1.625rem;
  --fs-29px: 1.813rem;
  --fs-32px: 2rem;

  /*FONT WEIGHTS*/
  --fw-regular:400;
  --fw-medium:500;
  --fw-semibold:600;
  --fw-bold:700;
  --fw-extrabold:800;

  /*........................OLD SCHEMA - TO BE REMOVED LATER .............................*/
  /*COLORS SCHEHMA*/
 
}
html,
body {
  height: 100%;
}
body {
  margin: 0;
  overflow-x: hidden;
}
 .mat-form-field .mat-form-field-invalid .ng-invalid{
  border: 1px solid red !important;
}
.mat-form-field-appearance-outline.mat-focused.mat-warn .mat-form-field-outline-thick, .mat-form-field-appearance-outline.mat-form-field-invalid.mat-form-field-invalid .mat-form-field-outline-thick {
  color: #f44336;
}
mat-form-field  .mat-form-field-invalid + .ng-invalid{
  border: 1px solid red !important;
}
.date-container  .mat-form-field-invalid .ng-invalid{
  color: #f44336;
  border: 1px solid red !important;
}
.mat-form-field-type-mat-input  .mat-form-field-appearance-fill  .mat-form-field-can-float  .ng-star-inserted  .mat-form-field-should-float  .ng-dirty   .ng-invalid   .mat-form-field-invalid  .ng-touched{
  color: #f44336;
  border: 1px solid red !important;
}
.mat-form-field.mat-form-field-invalid .mat-form-field-ripple, .mat-form-field.mat-form-field-invalid .mat-form-field-ripple.mat-accent{
  color: red;
}
ngx-intl-tel-input {
  background-color: #00abee;
}
.ng-touched .ng-invalid  .iti--allow-dropdown input{
  border:1px solid red;
  
}
.ng-untouched .ng-invalid  .iti--allow-dropdown input{
  border:1px solid black;
  
}
.ng-touched .ng-valid  .iti--allow-dropdown input{
  border:1px solid black;
  
}
ng-dirty ng-valid ng-touched
// .mat-form-field-invalid {
//   color: #f44336;
//   border: 1px solid red !important;
// }
// .ng-invalid{
//   color: #f44336;
//   border: 1px solid red !important;
// }
.tripIdeasTabs .mat-tab-header {
  border-bottom: 1px solid #ff215c;
}

.tripIdeasTabs .mat-tab-header .mat-tab-labels .mat-tab-label {
  color: black;
  opacity: 1;
  min-width: 100px;
  border-start-end-radius: 10px;
  border-start-start-radius: 10px;
  background-color: #e5e5e5;
  margin-left: 1%;
  margin-right: 1%;
}

.tripIdeasTabs .mat-tab-header .mat-tab-label.mat-tab-label-active {
  background-color: #ff215c;
  border-bottom-color: #ff215c;
  border-start-end-radius: 10px;
  border-start-start-radius: 10px;
  min-width: 100px;
  margin-left: 0%;
  margin-right: 0%;
}

.tripIdeasTabs
  .mat-tab-header
  .mat-tab-label.mat-tab-label-active
  .mat-tab-label-content {
  background-color: #ff215c !important;
  color: black !important;
  font-size: 16px;
  font-family: Inter, "Lucida Sans Unicode", Geneva, Verdana, sans-serif;
}

.tripIdeasTabs .mat-tab-header .mat-ink-bar {
  background-color: #ff215c;
}

.tripIdeasTabs
  .mat-tab-header
  .mat-tab-labels
  .mat-tab-label
  .mat-tab-label-content {
  color: #262626;
  font-size: 16px;
  font-family: Inter, "Lucida Sans Unicode", Geneva, Verdana, sans-serif;
}

.custom-slider .ngx-slider .ngx-slider-pointer {
  cursor: pointer;
  width: 18px !important;
  height: 18px !important;
  top: -8px !important;
  background-color: var(--color-secondary) !important;
  z-index: 3;
  border-radius: 16px;
  border: 2px solid var(--color-secondary) !important;
}
.mat-form-field-appearance-outline.mat-focused.mat-warn .mat-form-field-outline-thick, .mat-form-field-appearance-outline.mat-form-field-invalid.mat-form-field-invalid .mat-form-field-outline-thick {
  color: #f44336;
}


.mat-expansion-panel-header:not([aria-disabled=true]) {
  cursor: pointer;
  height: 100% !important;
}
.custom-slider .ngx-slider .ngx-slider-pointer::after {
  display: none;
}
.ar .mat-datepicker-toggle, .mat-datepicker-content .mat-calendar-next-button, .mat-datepicker-content .mat-calendar-previous-button{
  transform: rotate(360deg) !important;
}
.ar .bookM{
  text-align: inherit !important;

  direction: ltr;
  
} 
.custom-slider .ngx-slider .ngx-slider-selection {
  background: var(--color-secondary);
}

.ngx-slider .ngx-slider-bubble {
  color: var(--color-darkgrey) !important;
}
.offcanvas.show {
  transform: none;
  width:500px !important;
}
.ngx-slider .ngx-slider-bubble{
  color: var( --color-darkgrey) !important;
}

.filterCheckbox .mat-checkbox-inner-container {
  // height: 18px;
}
.filterCheckbox .mat-checkbox-frame {
  // border-radius: 5px !important;
}
.modal-dialog {
  max-width: 48rem !important;
}

.filterCheckbox .mat-checkbox-layout .mat-checkbox-label {
  line-height: 20px;
}

.customFilterCheckBoxMargin .mat-checkbox-layout .mat-checkbox-label {
  margin-top: 10%;
}
.carousel-control-prev,
.carousel-control-next {
  color: #b3b1b1;
}

/* Importing Bootstrap SCSS file. */
@import "~bootstrap/scss/bootstrap";
.phonenumber {
  width: 100%;
  border: 1px solid #e8e8e8;
  height: 41.5px;
  border-radius: 4px;
  padding-left: 65px !important;
}
[dir="rtl"] .float-end {
  float: left !important;
}
[dir="rtl"]
{ .iconMenu {
  float: left !important;
}
.wraber{

  direction: rtl !important;
  text-align: right !important;

}
}

.iti__country-list {
  direction: ltr;
}

* {
  font-family: inter;
}

